<template>
  <cp-general-modal
    ref="issuerAffiliate"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="affData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      :label="$t('affiliates.affiliateModal.input.name')"
    />

    <div
      v-if="titleData.type === 'Edit'"
      class="row mt-2"
    >
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('affiliates.affiliateModal.label.createdAt') }}
        </label> <br>
        <span>
          {{ model.createdAt | dateFilter }}
        </span>
      </div>
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('affiliates.affiliateModal.label.updatedAt') }}
        </label>  <br>
        <span>
          {{ model.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'IssuerAffiliateModal',
  components: {
    CpGeneralModal,
    CpInput,
  },
  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    affData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    getTitleName() {
      return this.$t('affiliates.affiliateModal.title', [this.titleData.label]);
    },
  },
  watch: {
    affData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },
  methods: {
    handleSubmit() {
      const action = this.model.id ? 'update' : 'create';
      this.$emit('onSubmit', { action, affiliate: this.model });
    },
  },
};
</script>
