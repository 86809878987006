<template>
  <div>
    <cp-head-table
      :title="$t('affiliates.title')"
      :button-title="$t('affiliates.button.affiliate.add')"
      title-icon="ios-build"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerAffiliates/getIssuerAffiliates"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="name"
        slot-scope="{ rowData }"
      >
        <a
          href="#"
          @click.prevent="toEditModal(rowData.item)"
        >
          {{ rowData.item.name }}
        </a>
      </template>

      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.delete')"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.delete') }}
        </b-btn>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-affiliate-modal
      ref="CpAffiliateModal"
      :title-data="titleData"
      :aff-data="affData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpAffiliateModal from './issuer-affiliate-modal';

export default {
  name: 'Affiliates',

  metaInfo: {
    title: 'Affiliates',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpDeleteModal,
    CpAffiliateModal,
  },

  data() {
    return {
      fields: [
        { key: 'name', i18nKey: 'affiliates.label.table.name', tdClass: 'align-middle' },
        {
          key: 'createdAt', i18nKey: 'affiliates.label.table.createdAt', tdClass: 'align-middle',
        },
        { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
      ],
      deleteItem: null,
      affData: {},
      titleData: {},
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },
  methods: {
    ...mapActions('issuerAffiliates', [
      'create',
      'getById',
      'update',
      'delete',
    ]),

    hideModal() {
      this.$refs.CpAffiliateModal.$refs.issuerAffiliate.hide();
    },

    showModal() {
      this.$refs.CpAffiliateModal.$refs.issuerAffiliate.show();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        label: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.affData = {};
      this.showModal();
    },

    toEditModal({ id, issuerId }) {
      this.titleData = {
        type: 'Edit',
        label: this.$t('common.button.edit'),
        icon: 'md-create',
      };

      this.getById({
        issuerId,
        affiliateId: id,
      }).then((data) => {
        this.affData = data;
        this.showModal();
      });
    },

    removeItem() {
      const { id, issuerId } = this.deleteItem;
      this.delete({
        issuerId,
        affiliateId: id,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    saveChanges({ action, affiliate }) {
      this[action]({
        issuerId: this.$route.params.idIssuer,
        affiliate,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },
  },
};
</script>
